import { Global } from '@emotion/core'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Styled } from 'theme-ui'

import { MDXProvider } from '@mdx-js/react'
import { preToCodeBlock } from 'mdx-utils'

import { globalStyles } from '../styles'
import mediaqueries from '../styles/media'
import Header from './Header'
import LeftSidebar from './LeftSidebar'
import RightSidebar from './RightSidebar'
import { Code } from './code'

const components = {
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    if (props) {
      return <Code {...props} />
    } else {
      return <pre {...preProps} />
    }
  }
}

const Layout = ({ children, tableOfContents, location }) => {
  const [navOpen, setNavOpen] = useState(false)
  return <MDXProvider components={components}>
    <Styled.root>
      <Global styles={globalStyles} />
      <Header navOpen={navOpen} setNavOpen={setNavOpen} />
      <SiteWrapper>
        <LeftSidebar navOpen={navOpen} />
        <SiteContentWrapper>
          <SiteContent navOpen={navOpen}>{children}</SiteContent>
        </SiteContentWrapper>
        {tableOfContents.items && (
          <RightSidebar tableOfContents={tableOfContents} location={location} />
        )}
      </SiteWrapper>
    </Styled.root>
  </MDXProvider>
}

const SiteWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  background: ${p => p.theme.colors.background};
  transition: background 0.25s var(--ease-in-out-quad);
`

const SiteContentWrapper = styled.div`
  flex-grow: 1;
  min-width: 20rem;
`

const SiteContent = styled.main`
  padding: 2rem 1rem 2rem;
  transition: 0.25s var(--ease-in-out-quad);
  opacity: ${p => (p.navOpen ? 0.3 : 1)};
  transform: ${p => (p.navOpen ? `translateX(16rem)` : null)};
  ${mediaqueries.desktop_up`
    transform: translateX(0);
    opacity: 1;
    padding: 8rem 5rem 5rem;
  `}
`
//    max-width: 60vw;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  tableOfContents: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default Layout
