import React from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwl'

import './code.css'

const RE = /^{([\d,-]+)}$/

const calculateLinesToHighlight = meta => {
  if (!RE.test(meta.lines)) return () => false;

  const lineNumbers = RE.exec(meta.lines)[1]
    .split(`,`)
    .map(v => v.split(`-`).map(x => parseInt(x,10)))
  return lineNumber => {
    const current = lineNumber + (meta.offset || 0)
    return lineNumbers.some(([start,end]) =>
      end ? current >= start && current <= end : current === start
    )
  }
}

export const Code = ({codeString, language, metastring: meta, ...props}) => {

  const metadata = (RE.test(meta)) ? {lines: meta} : JSON.parse(meta || '{}')
  metadata.noLineNumbers = language === 'bat' || metadata.noLineNumbers
  const shouldHighlightLine = calculateLinesToHighlight(metadata)

  return <Highlight
    {...defaultProps}
    code={codeString}
    language={language}
    theme={theme}
    {...props}
  >
    {({className, style, tokens, getLineProps, getTokenProps}) => <div className="gatsby-highlight" data-language={language}>
      <pre className={className} style={style}>
        {tokens.map((line,i) => {
          const lineProps = getLineProps({line, key: i})
          if (shouldHighlightLine(i)) {
            lineProps.className = `${lineProps.className} highlight-line`
          }
          return <div {...lineProps}>
            {!metadata.noLineNumbers && <span className="line-number-style">{i+(metadata.offset || 0)}</span>}
            {line.map((token,key) => <span {...getTokenProps({token,key})} />)}
          </div>
        })}
      </pre>
    </div>}
  </Highlight>
}
