import { css } from '@emotion/core';

const globalStyles = css`
  /**
   * Thanks to Benjamin De Cock
   * https://gist.github.com/bendc/ac03faac0bf2aee25b49e5fd260a727d
   */
  :root {
    --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
    --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  :root {
    box-sizing: border-box;
  }

  body {
    margin: 0;
  }

  /* https://github.com/gatsbyjs/gatsby/issues/15486 */
  .gatsby-resp-image-image {
    width: 100%;
    height: 100%;
    margin: 0;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
  }
  .gatsby-resp-image-wrapper {
    box-shadow: 0 0 12px #666;
  }
  .custom-block.danger > .custom-block-body > * {
    border-left: solid 8px #966;
    padding: 4px 12px;
    background-color: #633;
    ::first-letter {
      color: inherit;
      font-size: 100%;
    }
  }
  .custom-block.warning > .custom-block-body > * {
    border-left: solid 8px #963;
    padding: 4px 12px;
    background-color: #643;
    ::first-letter {
      color: inherit;
      font-size: 100%;
    }
  }
  .custom-block.info > .custom-block-body > * {
    border-left: solid 8px #359;
    padding: 4px 12px;
    background-color: #346;
    ::first-letter {
      color: inherit;
      font-size: 100%;
    }
  }
  .custom-block.note > .custom-block-body > * {
    border-left: solid 8px #555;
    padding: 4px 12px;
    background-color: #222;
    ::first-letter {
      color: inherit;
      font-size: 100%;
    }
  }
  .custom-block.task > .custom-block-body > * {
    border-left: solid 8px #696;
    padding: 4px 12px;
    background-color: #363;
    ::first-letter {
      color: inherit;
      font-size: 100%;
    }
  }
`;

export default globalStyles;
